.application-form .legend-div {
    display: flex;
}

.application-form .legend-div .legend {
    width: 50%;
}

.application-form .legend-div .right {
    text-align: right;
    width: 100%;
}
