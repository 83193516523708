:root {
    --font-size: 1rem;
}

body {
    background: var(--dark);
    color: var(--light);
    min-height: 100%;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.center {
    text-align: center;
}

.button-wrapper {
    text-align: right;
}
.button-wrapper a,
.button-wrapper button {
    margin: 0.5rem;
}

button.save {
    margin-right: 0;
}

fieldset.application-access legend {
    padding-top: 10px;
}

fieldset.application-access .form-group {
    padding-left: 20px;
}

.dark-on-light {
    background-color: var(--light);
    color: var(--dark);
}

.page-title header div {
    display: inline-block;
    width: 50%;
    text-align: right;
}

.page-title header div h2 {
    text-align: left;
}

.filter-wrapper input {
    margin: 5px 5px;
}

.company-filter {
    display: inherit;
}

.action-icon {
    color: var(--blue);
    font-size: 1.2rem;
}

div.action-icon {
    display: inline-block;
}
