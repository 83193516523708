.navigation-header {
    text-align: left;
    height: 60px;
}

.navigation-header h1 {
    display: inline-block;
    width: 50%;
}

.currentUser {
    text-align: right;
    display: inline-block;
    width: 50%;
}

.header-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: 5px;
}

.page-content-body {
    background-color: var(--light);
    color: var(--dark);
}

.application-form .legend-div {
    display: flex;
}

.application-form .legend-div .legend {
    width: 50%;
}

.application-form .legend-div .right {
    text-align: right;
    width: 100%;
}

.language-javascript {
    background-color: var(--dark);
}

.language-javascript pre {
    color: var(--light);
}

:root {
    --font-size: 1rem;
}

body {
    background: var(--dark);
    color: var(--light);
    min-height: 100%;
    font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.center {
    text-align: center;
}

.button-wrapper {
    text-align: right;
}
.button-wrapper a,
.button-wrapper button {
    margin: 0.5rem;
}

button.save {
    margin-right: 0;
}

fieldset.application-access legend {
    padding-top: 10px;
}

fieldset.application-access .form-group {
    padding-left: 20px;
}

.dark-on-light {
    background-color: var(--light);
    color: var(--dark);
}

.page-title header div {
    display: inline-block;
    width: 50%;
    text-align: right;
}

.page-title header div h2 {
    text-align: left;
}

.filter-wrapper input {
    margin: 5px 5px;
}

.company-filter {
    display: inherit;
}

.action-icon {
    color: var(--blue);
    font-size: 1.2rem;
}

div.action-icon {
    display: inline-block;
}

