.navigation-header {
    text-align: left;
    height: 60px;
}

.navigation-header h1 {
    display: inline-block;
    width: 50%;
}

.currentUser {
    text-align: right;
    display: inline-block;
    width: 50%;
}

.header-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: 5px;
}
